// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import '../stylesheets/application';
import '../stylesheets/cardform';
import "../stylesheets/actiontext";
import 'trix/dist/trix.css'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', function() {
    $(function() {
        setTimeout("$('.alert_block').fadeOut('slow')", 3000);
    });
    
    $(function () {
        $("#q_input_date_gteq, #q_input_date_lteq_end_of_day").on('change',calculateDateDifference);
    });
    function calculateDateDifference() {
        var startDate = $("#q_input_date_gteq").val();
        var endDate = $("#q_input_date_lteq_end_of_day").val();

        if (startDate && endDate) {
            var diffInMilliseconds = new Date(endDate) - new Date(startDate);
            var diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
            
            // 月単位の差に変換
            var diffInMonths = diffInDays / 31;

            if (diffInMonths > 1) {
                alert("エラー：1ヶ月以上の期間は許可されていません。");
                $("#q_input_date_gteq, #q_input_date_lteq_end_of_day").val("");
            } 
        }
    }
    window.gtranslateSettings = {
      "default_language": "ja",
      "languages": ["ja", "zh-CN", "zh-TW", "th", "vi", "ko", "tl", "pt", "en"],
      "wrapper_selector": ".gtranslate_wrapper",
      "switcher_horizontal_position": "right",
      "alt_flags": {"pt": "brazil"}
    };

    var script = $("<script>", {
      src: "https://cdn.gtranslate.net/widgets/latest/float.js",
      defer: true
    });

    $("head").append(script);

    $('.gt_float_switcher').on('click', 'a', function(event) {
    // Turbolinksを無効にする
    Turbolinks.visit($(this).attr('href'), { action: 'replace' });

    // クリックによる通常の遷移を無効にする
    event.preventDefault();
  });
});
